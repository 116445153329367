import _constant from "./constant";
import _createInverter from "./_createInverter";
import _identity from "./identity";
var exports = {};
var constant = _constant,
  createInverter = _createInverter,
  identity = _identity;

/** Used for built-in method references. */
var objectProto = Object.prototype;

/**
 * Used to resolve the
 * [`toStringTag`](http://ecma-international.org/ecma-262/7.0/#sec-object.prototype.tostring)
 * of values.
 */
var nativeObjectToString = objectProto.toString;

/**
 * Creates an object composed of the inverted keys and values of `object`.
 * If `object` contains duplicate values, subsequent values overwrite
 * property assignments of previous values.
 *
 * @static
 * @memberOf _
 * @since 0.7.0
 * @category Object
 * @param {Object} object The object to invert.
 * @returns {Object} Returns the new inverted object.
 * @example
 *
 * var object = { 'a': 1, 'b': 2, 'c': 1 };
 *
 * _.invert(object);
 * // => { '1': 'c', '2': 'b' }
 */
var invert = createInverter(function (result, value, key) {
  if (value != null && typeof value.toString != "function") {
    value = nativeObjectToString.call(value);
  }
  result[value] = key;
}, constant(identity));
exports = invert;
export default exports;